<template>
  <div class="page-content">
    <section class="sheet padding-10mm" v-if="reservation && reservation.customer_nationality === 'IT'">
    <div class="row margin-bottom-40">
      <div class="col-xs-4">
        <img style="border:0.1mm solid #ddd;" class="ticket-logo" src="../assets/logo_rivieranuragica_v2.jpg">
      </div>
      <div class="col-xs-4">
        <img class="ticket-logo" src="../assets/logo_geseco.jpg">
      </div>
      <div class="col-xs-4">
        <div class="barcode-container">
          <img id="ticket-barcode" class="barcode-top" :src="'https://barcode.tec-it.com/barcode.ashx?data=' + reservation.ticket_code + '&code=Code128&multiplebarcodes=true&translate-esc=true&unit=Fit&dpi=96&imagetype=Gif&rotation=90&color=%23000000&bgcolor=%23ffffff&codepage=&qunit=Mm&quiet=0'">
        </div>
      </div>
    </div>
    <div class="row margin-bottom-30">
      <div class="col-xs-6">
          <h3 v-if="reservation.document_type === Keys.DOCUMENT_TYPES.INVOICE"><b>Fattura N. {{reservation.creator.prefix_invoice}}{{reservation.invoice_number}}</b></h3>
        <h4>Organizzatore</h4>
        <p><b>Geseco Surl</b></p>
        <p>Viale Costa Smeralda, 56<br/>Arzachena (SS)</p>
        <p>Telefono: +39 345 7200094<br/>Email: archeologia@gesecoarzachena.it</p>
      </div>
      <div class="col-xs-6">
        <h4>Cliente</h4>
        <p v-if="reservation.document_type === Keys.DOCUMENT_TYPES.INVOICE"><span id="ticket_customer_vat_number">P. IVA: {{reservation.customer_vat_number}}</span></p>
        <p><b><span id="ticket_customer_first_name">{{StringsHelper.getStringOrNA(reservation.customer_first_name)}}</span> <span id="ticket_customer_last_name" v-if="reservation.document_type !== Keys.DOCUMENT_TYPES.INVOICE">{{StringsHelper.getStringOrNA(reservation.customer_last_name)}}</span></b></p>
        <p v-if="reservation.document_type !== Keys.DOCUMENT_TYPES.INVOICE"><span id="ticket_customer_email">{{StringsHelper.getStringOrNA(reservation.customer_email)}}</span><br/><span id="ticket_customer_phone">{{StringsHelper.getStringOrNA(reservation.customer_phone)}}</span></p>
        <p v-if="reservation.document_type !== Keys.DOCUMENT_TYPES.INVOICE"><span id="ticket_customer_nationality">{{StringsHelper.getStringCountryFromISO(reservation.customer_nationality)}}</span></p>
        <p v-if="reservation.document_type === Keys.DOCUMENT_TYPES.INVOICE"><span id="ticket_customer_address">{{reservation.customer_address}}</span></p>
        <p v-if="reservation.document_type === Keys.DOCUMENT_TYPES.INVOICE"><span id="ticket_customer_pec">{{reservation.customer_pec}}</span></p>
        <p v-if="reservation.document_type === Keys.DOCUMENT_TYPES.INVOICE"><span id="ticket_customer_customer_receipt_code">COD. DEST: {{reservation.customer_recipient_code}}</span></p>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div style="border:0.1mm solid #ddd;padding:4mm;">
          <h4>I visitatori sono gentilmente pregati di:</h4>
          <ul>
            <li>Non fumare.</li>
            <li>Non rimuovere nessun oggetto all'interno dell'area archeologica.</li>
            <li>Non entrare nella tomba.</li>
            <li>Non arrampicarsi e non camminare sulle strutture. Il danneggiamento del patrimonio archeologico è punito con l’arresto fino ad un anno o con l’ammenda non inferiore a euro 2.065 (Art. 733, co 1 del Codice penale)</li>
            <li>Non circolare in costume da bagno e/o a torso nudo.</li>
            <li>Non consumare cibi e bevande all'interno dell'area.</li>
            <li>Tenere i cani al guinzaglio.</li>
            <li>Non utilizzare droni e altri dispositivi al di fuori di macchine fotografiche e apparecchi di registrazione audio/video. I minori di 12 anni devono essere accompagnato da un adulto.</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ticket-footer">
      <table class="table table-bordered">
        <tbody>
        <tr>
          <td class="bold">Offerta</td>
          <td class="bold" colspan="3"><span id="ticket_product_list">
            <span id="product_list" v-for="(product, index) in reservation.product_list" :key="product">
              {{StringsHelper.getProduct(product)}}<span v-if="!(index===(reservation.product_list.length-1))">,</span>
            </span>
          </span></td>
          <td style="width:40mm;vertical-align: middle;" rowspan="4">
            <p class="bold text-center margin-bottom-15">Audio Guide</p>
            <img id="ticket-qr-code" :src="'https://api.qrserver.com/v1/create-qr-code/?size=180x180&data=' + reservation.ticket_code"></td>
        </tr>
        <tr>
          <td class="bold">Tariffa</td>
          <td colspan="3"><span id="ticket_rate_quantity_list">
            <span v-for="(rate, index) in reservation.rate_list" :key="index">
              {{rate.quantity}} x {{StringsHelper.getRate(rate.rate)}} <br v-if="!(index===(reservation.rate_list.length-1))" />
            </span>
          </span></td>
        </tr>
        <tr>
          <td class="bold" nowrap>Data ingresso</td>
          <td><span id="ticket_date">{{Utils.getShortDateStringFromISO(reservation.date)}}</span> <span id="ticket_hour">{{Utils.getHourStringFromISO(reservation.date)}}</span></td>
          <td v-if="reservation.document_type !== Keys.DOCUMENT_TYPES.INVOICE" class="bold" nowrap>Valido fino al</td>
          <td v-if="reservation.document_type !== Keys.DOCUMENT_TYPES.INVOICE"><span id="ticket_validity_date">{{Utils.getShortDateStringFromISO(ticket_validity_date)}}</span></td>
        </tr>
        <tr>
          <td class="bold" nowrap>Data acquisto</td>
          <td><span id="ticket_created_date">{{Utils.getDateStringFromISO(reservation.purchase_date)}}</span></td>
          <td class="bold">Importo</td>
          <td>€<span id="ticket_price">{{StringsHelper.getStringOrNA(reservation.price_final)}}</span></td>
        </tr>
        <tr>
          <td colspan="6" style="font-size: 13px;">
            Per ascoltare le nostre audio guide, ti invitiamo a scaricare l'applicazione RivieraNuragica. Una volta scaricata l'app, seleziona il sito archeologico che stai visitando, tocca l'opzione "Sblocca audio guide" e, infine, inquadra il QR Code.</td>
        </tr>
        </tbody>
      </table>
    </div>
  </section>
    <section class="sheet padding-10mm" v-else-if="reservation">
      <div class="row margin-bottom-40">
        <div class="col-xs-4">
          <img style="border:0.1mm solid #ddd;" class="ticket-logo" src="../assets/logo_rivieranuragica_v2.jpg">
        </div>
        <div class="col-xs-4">
          <img class="ticket-logo" src="../assets/logo_geseco.jpg">
        </div>
        <div class="col-xs-4">
          <div class="barcode-container">
            <img id="ticket-barcode" class="barcode-top" :src="'https://barcode.tec-it.com/barcode.ashx?data=' + reservation.ticket_code + '&code=Code128&multiplebarcodes=true&translate-esc=true&unit=Fit&dpi=96&imagetype=Gif&rotation=90&color=%23000000&bgcolor=%23ffffff&codepage=&qunit=Mm&quiet=0'">
          </div>
        </div>
      </div>
      <div class="row margin-bottom-30">
        <div class="col-xs-6">
          <h3 v-if="reservation.document_type === Keys.DOCUMENT_TYPES.INVOICE"><b>Invoice No. {{reservation.creator.prefix_invoice}}{{reservation.invoice_number}}</b></h3>
          <h4>Manager</h4>
          <p><b>Geseco Surl</b></p>
          <p>Viale Costa Smeralda, 56<br/>Arzachena (SS)</p>
          <p>Telefono: +39 345 7200094<br/>Email: archeologia@gesecoarzachena.it</p>
        </div>
        <div class="col-xs-6">
          <h4>Customer</h4>
          <p v-if="reservation.document_type === Keys.DOCUMENT_TYPES.INVOICE"><span id="ticket_customer_vat_number">VAT: {{reservation.customer_vat_number}}</span></p>
          <p><b><span id="ticket_customer_first_name">{{StringsHelper.getStringOrNA(reservation.customer_first_name)}}</span> <span id="ticket_customer_last_name" v-if="reservation.document_type !== Keys.DOCUMENT_TYPES.INVOICE">{{StringsHelper.getStringOrNA(reservation.customer_last_name)}}</span></b></p>
          <p v-if="reservation.document_type !== Keys.DOCUMENT_TYPES.INVOICE"><span id="ticket_customer_email">{{StringsHelper.getStringOrNA(reservation.customer_email)}}</span><br/><span id="ticket_customer_phone">{{StringsHelper.getStringOrNA(reservation.customer_phone)}}</span></p>
          <p v-if="reservation.document_type !== Keys.DOCUMENT_TYPES.INVOICE"><span id="ticket_customer_nationality">{{StringsHelper.getStringCountryFromISOEN(reservation.customer_nationality)}}</span></p>
          <p v-if="reservation.document_type === Keys.DOCUMENT_TYPES.INVOICE"><span id="ticket_customer_address">{{reservation.customer_address}}</span></p>
          <p v-if="reservation.document_type === Keys.DOCUMENT_TYPES.INVOICE"><span id="ticket_customer_pec">{{reservation.customer_pec}}</span></p>
          <p v-if="reservation.document_type === Keys.DOCUMENT_TYPES.INVOICE"><span id="ticket_customer_customer_receipt_code">COD. DEST: {{reservation.customer_recipient_code}}</span></p>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div style="border:0.1mm solid #ddd;padding:4mm;">
            <h4>Visitors are kindly requested to:</h4>
            <ul>
              <li>Do not smoke.</li>
              <li>Do not remove any objects from the archaeological area.</li>
              <li>Do not enter the tomb.</li>
              <li>Do not climb or walk on structures. Damage to archaeological heritage is punishable by arrest of up to one year or a fine of not less than 2,065 euros (Art. 733, co 1 del Codice penale)</li>
              <li>Do not circulate in a swim suit and/or shirtless.</li>
              <li>Do not drink or eat within the area.</li>
              <li>Keep dogs on a leash.</li>
              <li>Do not use drones and other devices outside of cameras and audio/video recording equipment. Children under the age of 12 must be accompanied by an adult.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="ticket-footer">
        <table class="table table-bordered">
          <tbody>
          <tr>
            <td class="bold">Rate</td>
            <td class="bold" colspan="3"><span id="ticket_product_list">
            <span id="product_list" v-for="(product, index) in reservation.product_list" :key="product">
              {{StringsHelper.getProduct(product)}}<span v-if="!(index===(reservation.product_list.length-1))">,</span>
            </span>
          </span></td>
            <td style="width:40mm;vertical-align: middle;" rowspan="4">
              <!-- TODO enable when audioguide en is ready -->
<!--              <p class="bold text-center margin-bottom-15">Audio Guide</p>-->
              <img id="ticket-qr-code" :src="'https://api.qrserver.com/v1/create-qr-code/?size=180x180&data=' + reservation.ticket_code"></td>
          </tr>
          <tr>
            <td class="bold">Rate</td>
            <td colspan="3"><span id="ticket_rate_quantity_list">
            <span v-for="(rate, index) in reservation.rate_list" :key="index">
              {{rate.quantity}} x {{StringsHelper.getRateEn(rate.rate)}} <br v-if="!(index===(reservation.rate_list.length-1))" />
            </span>
          </span></td>
          </tr>
          <tr>
            <td class="bold" nowrap>Date of entry</td>
            <td><span id="ticket_date">{{Utils.getShortDateStringFromISO(reservation.date)}}</span> <span id="ticket_hour">{{Utils.getHourStringFromISO(reservation.date)}}</span></td>
            <td v-if="reservation.document_type !== Keys.DOCUMENT_TYPES.INVOICE" class="bold" nowrap>Valid until</td>
            <td v-if="reservation.document_type !== Keys.DOCUMENT_TYPES.INVOICE"><span id="ticket_validity_date">{{Utils.getShortDateStringFromISO(ticket_validity_date)}}</span></td>
          </tr>
          <tr>
            <td class="bold" nowrap>Purchase date</td>
            <td><span id="ticket_created_date">{{Utils.getDateStringFromISO(reservation.purchase_date)}}</span></td>
            <td class="bold">Amount</td>
            <td>€<span id="ticket_price">{{StringsHelper.getStringOrNA(reservation.price_final)}}</span></td>
          </tr>
          <tr>
            <td colspan="6" style="font-size: 13px;">
              To listen to our audio guides, we invite you to download the RivieraNuragica app. Once the app is downloaded, select the archaeological site you are visiting, tap on the "Unlock audio guides" option, and finally, frame the QR Code.</td>
          </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
import baseMixin from "../common/baseMixin";
import moment from 'moment'

export default {
    name: "Ticket",
    mixins: [baseMixin],
    props: {
        reservation: {
            type: Object,
            required: true
        }
    },
    data: () => {
        return {
          ticket_validity_date: new Date().toISOString()
        }
    },
    created() {
        document.body.classList.add("A4");
    },
    beforeDestroy() {
        document.body.classList.remove("A4");
    },
    watch: {
        reservation: function (newVal) {
            this.ticket_validity_date = moment(newVal.date).add(7, 'day')
        }
    }
}
</script>
